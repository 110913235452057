const HOST_DEV = `artifact.aikaan.io`;
let backendHost = ``;
let hostToConnect = ``;

const { host } = window.location;

if (host === `localhost:3000`) {
    hostToConnect = HOST_DEV;
} else {
    hostToConnect = host;
}

backendHost = `https://${hostToConnect}`;

export const BASE_URL = backendHost;