import { createMuiTheme, makeStyles } from '@material-ui/core/styles';

const colors = {
    egyptianBlue: '#0A369D',
    uranianBlue: '#A5D8FF',
    charcoal: '#3C464D'
};

export default createMuiTheme ({
    palette: {
        primary: {
            main: colors.egyptianBlue
        },
        secondary: {
            main: colors.uranianBlue
        },
        text: {
            main: colors.charcoal,
        },
    },
    overrides: {
        MuiTypography: {
            root: {
                color: colors.charcoal,
            },
        },
    },
});

export const useStyles = makeStyles(theme => {
    return {
        appBar: {
            backgroundColor: `${theme.palette.background.paper} !important`,
        },
        toolbar: {
            padding: '14px 40px',
            flexWrap: 'wrap',
        },
        toolbarLogo: {
            width: '200px',
        },
        toolbarLogoLink: {
            marginRight: 'auto',
        },
        navLink: {
            margin: theme.spacing(1, 1.5),
        },
        heroContent: {
            background: `linear-gradient(180deg, #FFFFFF, ${theme.palette.secondary.main})`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            height: 'calc(100vh - 80px)',
        },
        heroHeader: {
            fontFamily: `'Roboto Slab', 'serif'`,
            color: theme.palette.primary.main,
            marginTop: '-1em',
        },
        heroSubHeader: {
            width: '474px',
        },
        heroButtons: {
            marginTop: theme.spacing(3),
        },
        poweredBy: {
            position: 'absolute',
            bottom: '0',
            right: '0',
            margin: theme.spacing(3),
        },
        formContent: {
            background: theme.palette.background.paper,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            flexDirection: 'column',
            minHeight: 'calc(100vh - 80px)',
        },
        stepperContainer: {
            width: '800px',
            padding: 0,
            margin: '120px 0px 60px',
        },
        stepLinePosition: {
            top: 10,
            left: 'calc(-50% + 40px)',
            right: 'calc(50% + 40px)',
        },
        stepLine: {
            color: colors.charcoal,
            borderColor: colors.charcoal,
            borderTopWidth: 2,
        },
        stepIcon: {
            transform: 'scale(2)',
        },
        stepLabel: {
            fontSize: '20px',
            color: theme.palette.text.main,
            paddingTop: '30px',
        },
        formContainer: {
            paddingBottom: '60px',
        },
        fieldsContainer: {
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'flex-start',
            marginBottom: '30px',
            '&:last-child': {
                marginBottom: '0px',
            },
        },
        fieldItemOne: {
            marginLeft: 'auto',
        },
        fieldItemTwo: {
            paddingLeft: '50px',
            width: '450px',
            marginBottom: 'auto',
        },
        fieldWidth: {
            width: '300px',
        },
        uploadButtonWrapper: {
            maxWidth: '450px',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row-reverse',
            '& .MuiTypography-root': {
                paddingRight: '10px',
            },
            '& .MuiListItem-root': {
                textAlign: 'right',
                paddingTop: 0,
                paddingBottom: 0,
                '& .MuiListItemText-multiline': {
                    margin: 0,
                },
            },
        },
        backButton: {
            marginRight: theme.spacing(1),
        },
        progressBar: {
            width: '400px',
            height: '10px',
            borderRadius: '20px',
            marginBottom: '10px',
        },
        errorActions: {
            '& > *': {
                margin: theme.spacing(1),
            },
        },
    };
});