import { ThemeProvider } from '@material-ui/core';
import React, { Component } from 'react';
import CreateArtifact from './components/CreateArtifact';
import Hero from './components/Hero';
import theme from './theme';

export default class App extends Component {
    render() {
        return (
            <ThemeProvider theme={theme}>
                <Hero />
                <CreateArtifact />
            </ThemeProvider>
        );
    }
}