import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React, { Fragment } from 'react';
import { createArtifact } from '../actions';
import { useStyles } from '../theme';
import { formatBytes, isStringValid } from '../utils';

const artifactTypes = {
    FILE: 'file',
    SCRIPT: 'script',
    DEBIAN: 'debian',
    ROOTFS: 'root-fs',
    AIKAAN_OTA: 'Aikaan-OTA',
    AIKAAN_OTA_REBOOT: 'Aikaan-OTA-Reboot',
    APK: 'Apk',
}

const optionsForArtifactType = [
    { text: 'File', value: artifactTypes.FILE },
    { text: 'Script', value: artifactTypes.SCRIPT },
    { text: 'Debian', value: artifactTypes.DEBIAN },
    { text: 'Rootfs', value: artifactTypes.ROOTFS },
    { text: 'Application', value: artifactTypes.AIKAAN_OTA },
    { text: 'Application with reboot', value: artifactTypes.AIKAAN_OTA_REBOOT },
    { text: 'APK', value: artifactTypes.APK },
];

const artifactInformation = {
    [artifactTypes.FILE]: 'Push a config file or a package file or simple text file to the target device(s)',
    [artifactTypes.SCRIPT]: 'Push script to the target device(s) and execute the same',
    [artifactTypes.DEBIAN]: 'Installs a debian package on the target device(s)',
    [artifactTypes.ROOTFS]: 'Complete root file system to be installed on target device(s)',
    [artifactTypes.AIKAAN_OTA]: 'Push a package file and a script to the target and execute the script',
    [artifactTypes.AIKAAN_OTA_REBOOT]: 'Push a package file and a script to the target and execute the script and reboot the target device(s)',
    [artifactTypes.APK]: 'Installs an android APK on target device(s)',
};

//const FILE_SIZE_LIMIT = 104857600;
const FILE_SIZE_LIMIT = 262144000; //250 MB

const optionsForAppType = [
    { text: 'User application', value: 0 },
    { text: 'System application', value: 1 }
];

const FieldsWithExplanation = props => {
    const classes = useStyles();

    return (
        <div className={classes.fieldsContainer}>
            <div className={classes.fieldItemOne}>
                {props.field}
            </div>
            <div className={classes.fieldItemTwo}>
                {props.explanation}
            </div>
        </div>
    );
}

const validateFileSize = selectedFile => {
    if (!selectedFile) {
        return false;
    }
    if (selectedFile && selectedFile.size > FILE_SIZE_LIMIT) {
        return false;
    }
    return true;
};

export default () => {
    const classes = useStyles();
    const steps = ['Select artifact type', 'Provide name and device type', 'Upload file'];

    const [activeStep, setActiveStep] = React.useState(0);
    const [artifactType, setArtifactType] = React.useState('');
    const [artifactName, setArtifactName] = React.useState('');
    const [deviceType, setDeviceType] = React.useState('');
    const [destinationDirectory, setDestinationDirectory] = React.useState('');
    const [file, setFile] = React.useState(null);
    const [script, setScript] = React.useState(null);
    const [appType, setAppType] = React.useState(0);

    const [isArtifactTypeValid, setArtifactTypeValidity] = React.useState(true);
    const [isArtifactNameValid, setArtifactNameValidity] = React.useState(true);
    const [isDeviceTypeValid, setDeviceTypeValidity] = React.useState(true);
    const [isDestinationDirectoryValid, setDestinationDirectoryValidity] = React.useState(true);
    const [isFileValid, setFileValidity] = React.useState(true);
    const [isScriptValid, setScriptValidity] = React.useState(true);

    const [uploadProgress, setUploadProgress] = React.useState(0);
    const [downloadProgress, setDownloadProgress] = React.useState(0);
    const [isResponseThrown, setResponseThrown] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');

    const handleNext = () => {
        if (activeStep === 0) {
            if (!(artifactType.length > 0)) {
                setArtifactTypeValidity(false);
                return;
            }
        }
        if (activeStep === 1) {
            if (!isStringValid(artifactName) || artifactName.includes(' ')) {
                setArtifactNameValidity(false);
                return;
            }
            if (!isStringValid(deviceType)) {
                setDeviceTypeValidity(false);
                return;
            }
        }
        if (activeStep === 2) {
            if (!validateStepThree()) {
                return;
            } else {
                submitForm();
            }
        }
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
        setArtifactType('');
        setArtifactName('');
        setDeviceType('');
        setDestinationDirectory('');
        setFile(null);
        setScript(null);
        setResponseThrown(false);
        setErrorMessage('');
    };

    const handleRetry = () => {
        setResponseThrown(false);
        setErrorMessage('');
        submitForm();
    }

    const validateStepThree = () => {
        let status = true;
        switch (artifactType) {
            case artifactTypes.FILE:
                if (!isStringValid(destinationDirectory)) {
                    setDestinationDirectoryValidity(false);
                    status = false;
                }
                if (!validateFileSize(file)) {
                    setFileValidity(false);
                    status = false;
                }
                break;

            case artifactTypes.SCRIPT:
                if (!validateFileSize(script)) {
                    setScriptValidity(false);
                    status = false;
                }
                break;

            case artifactTypes.DEBIAN:
                if (!validateFileSize(file)) {
                    setFileValidity(false);
                    status = false;
                }
                break;

            case artifactTypes.ROOTFS:
                if (!validateFileSize(file)) {
                    setFileValidity(false);
                    status = false;
                }
                break;

            case artifactTypes.AIKAAN_OTA:
                if (!isStringValid(destinationDirectory)) {
                    setDestinationDirectoryValidity(false);
                    status = false;
                }
                if (!validateFileSize(file)) {
                    setFileValidity(false);
                    status = false;
                }
                if (!validateFileSize(script)) {
                    setScriptValidity(false);
                    status = false;
                }
                break;

            case artifactTypes.AIKAAN_OTA_REBOOT:
                if (!isStringValid(destinationDirectory)) {
                    setDestinationDirectoryValidity(false);
                    status = false;
                }
                if (!validateFileSize(file)) {
                    setFileValidity(false);
                    status = false;
                }
                if (!validateFileSize(script)) {
                    setScriptValidity(false);
                    status = false;
                }
                break;

            case artifactTypes.APK:
                if (!validateFileSize(file)) {
                    setFileValidity(false);
                    status = false;
                }
                break;

            default:
                break;
        }
        return status;
    }

    const submitForm = () => {
        const requestPayload = new FormData();
        requestPayload.append('ArtifactType', artifactType);
        requestPayload.append('ArtifactName', artifactName);
        requestPayload.append('DeviceType', deviceType);

        switch (artifactType) {
            case artifactTypes.FILE:
                requestPayload.append('hasFile', true);
                requestPayload.append('hasShellScript', false);
                requestPayload.append('file', file);
                requestPayload.append('DestDir', destinationDirectory);
                break;

            case artifactTypes.SCRIPT:
                requestPayload.append('hasFile', false);
                requestPayload.append('hasShellScript', true);
                requestPayload.append('shellScript', script);
                break;

            case artifactTypes.DEBIAN:
                requestPayload.append('hasFile', true);
                requestPayload.append('hasShellScript', false);
                requestPayload.append('file', file);
                break;

            case artifactTypes.ROOTFS:
                requestPayload.append('hasFile', true);
                requestPayload.append('hasShellScript', false);
                requestPayload.append('file', file);
                break;

            case artifactTypes.AIKAAN_OTA:
                requestPayload.append('hasFile', true);
                requestPayload.append('file', file);
                requestPayload.append('hasShellScript', true);
                requestPayload.append('shellScript', script);
                requestPayload.append('DestDir', destinationDirectory);
                break;

            case artifactTypes.AIKAAN_OTA_REBOOT:
                requestPayload.append('hasFile', true);
                requestPayload.append('file', file);
                requestPayload.append('hasShellScript', true);
                requestPayload.append('shellScript', script);
                requestPayload.append('DestDir', destinationDirectory);
                break;

            case artifactTypes.APK:
                requestPayload.append('hasFile', true);
                requestPayload.append('file', file);
                requestPayload.append('hasShellScript', false);
                requestPayload.append('AppType', appType);
                break;

            default:
                break;
        }

        createArtifact(requestPayload, (preResponse, response, error) => {
            if (preResponse) {
                const { uploadProgress, downloadProgress } = preResponse;
                setUploadProgress(uploadProgress);
                setDownloadProgress(downloadProgress);
            }
            if (response) {
                setUploadProgress(0);
                setDownloadProgress(0);
                setResponseThrown(true);

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${artifactName}.mender`);
                document.body.appendChild(link);
                link.click();
            }
            if (error) {
                setUploadProgress(0);
                setDownloadProgress(0);
                setErrorMessage(error);
            }
        })();
    }

    const renderStepThree = () => {
        const { FILE, SCRIPT, DEBIAN, ROOTFS, AIKAAN_OTA, AIKAAN_OTA_REBOOT, APK } = artifactTypes;
        return (
            <Fragment>
                {(
                    (artifactType === FILE)
                    || (artifactType === AIKAAN_OTA)
                    || (artifactType === AIKAAN_OTA_REBOOT)) &&
                    <FieldsWithExplanation
                        field={
                            <TextField
                                className={classes.fieldWidth}
                                error={!isDestinationDirectoryValid}
                                helperText={!isDestinationDirectoryValid ? 'Please enter the directory path' : ''}
                                label='Destination directory'
                                onChange={event => setDestinationDirectory(event.target.value)}
                                onFocus={() => setDestinationDirectoryValidity(true)}
                                value={destinationDirectory}
                                variant='outlined'
                            />
                        }
                        explanation={
                            <Typography>
                                Path to where the installation should happen on the device
                            </Typography>
                        }
                    />
                }
                {(
                    (artifactType === FILE)
                    || (artifactType === DEBIAN)
                    || (artifactType === ROOTFS)
                    || (artifactType === AIKAAN_OTA)
                    || (artifactType === AIKAAN_OTA_REBOOT)
                    || (artifactType === APK)) &&
                    <FieldsWithExplanation
                        field={
                            <div className={classes.uploadButtonWrapper}>
                                <Button
                                    color='primary'
                                    onClick={() => {
                                        document.getElementById('uploadFile').click();
                                        setFileValidity(true);
                                    }}
                                    variant='contained'
                                >
                                    Upload file
                                    <input
                                        hidden
                                        id='uploadFile'
                                        onChange={event => {
                                            event.persist();
                                            setFile(event.target.files[0])
                                        }}
                                        type='file'
                                    />
                                </Button>
                                {
                                    !isFileValid &&
                                    <Typography>
                                        {`Please upload a file of size less than 250MB`}
                                    </Typography>
                                }
                                {
                                    file &&
                                    <List disablePadding>
                                        <ListItem>
                                            <ListItemText primary={file.name} secondary={formatBytes(file.size)} />
                                        </ListItem>
                                    </List>
                                }
                            </div>
                        }
                        explanation={
                            <Typography>
                                {`Upload the file which needs to be included in the artifact [Size <= 250MB]`}
                            </Typography>
                        }
                    />
                }
                {(
                    (artifactType === SCRIPT)
                    || (artifactType === AIKAAN_OTA)
                    || (artifactType === AIKAAN_OTA_REBOOT)) &&
                    <FieldsWithExplanation
                        field={
                            <div className={classes.uploadButtonWrapper}>
                                <Button
                                    color='primary'
                                    onClick={() => {
                                        document.getElementById('uploadScript').click();
                                        setScriptValidity(true);
                                    }}
                                    variant='contained'
                                >
                                    Upload script
                                    <input
                                        hidden
                                        id='uploadScript'
                                        onChange={event => {
                                            event.persist();
                                            setScript(event.target.files[0])
                                        }}
                                        type='file'
                                    />
                                </Button>
                                {
                                    !isScriptValid &&
                                    <Typography>
                                        {`Please upload a script file of size less than 250MB`}
                                    </Typography>
                                }
                                {
                                    script &&
                                    <List disablePadding>
                                        <ListItem>
                                            <ListItemText primary={script.name} secondary={formatBytes(script.size)} />
                                        </ListItem>
                                    </List>
                                }
                            </div>
                        }
                        explanation={
                            <Typography>
                                {`Upload the script that you wish to deploy and execute on your device [Size <= 250MB]`}
                            </Typography>
                        }
                    />
                }
                {
                    artifactType === APK &&
                    <FieldsWithExplanation
                        field={
                            <TextField
                                className={classes.fieldWidth}
                                label='Application type'
                                onChange={event => setAppType(event.target.value)}
                                select
                                value={appType}
                                variant='outlined'
                            >
                                {optionsForAppType.map((item, index) => (
                                    <MenuItem key={index} value={item.value}>{item.text}</MenuItem>
                                ))}
                            </TextField>
                        }
                        explanation={<Typography>Select your application type</Typography>}
                    />
                }
            </Fragment>
        );
    }

    const renderFormContent = () => {
        switch (activeStep) {
            case 0:
                return (
                    <FieldsWithExplanation
                        field={
                            <TextField
                                className={classes.fieldWidth}
                                error={!isArtifactTypeValid}
                                helperText={!isArtifactTypeValid ? 'Please select an artifact type' : ''}
                                label='Artifact type'
                                onChange={event => setArtifactType(event.target.value)}
                                onFocus={() => setArtifactTypeValidity(true)}
                                select
                                value={artifactType}
                                variant='outlined'
                            >
                                {optionsForArtifactType.map((item, index) => (
                                    <MenuItem key={index} value={item.value}>{item.text}</MenuItem>
                                ))}
                            </TextField>
                        }
                        explanation={
                            <Fragment>
                                <Typography>
                                    Select the type of artifact you want to create
                                </Typography>
                                <Typography>
                                    {artifactType && `${artifactInformation[artifactType]}`}
                                </Typography>
                            </Fragment>
                        }
                    />
                );
            case 1:
                return (
                    <Fragment>
                        <FieldsWithExplanation
                            field={
                                <TextField
                                    className={classes.fieldWidth}
                                    error={!isArtifactNameValid}
                                    helperText={!isArtifactNameValid ? 'Please enter a name without any space' : ''}
                                    label='Artifact name'
                                    onChange={event => setArtifactName(event.target.value)}
                                    onFocus={() => setArtifactNameValidity(true)}
                                    value={artifactName}
                                    variant='outlined'
                                />
                            }
                            explanation={<Typography>Enter a name for the artifact</Typography>}
                        />
                        <FieldsWithExplanation
                            field={
                                <TextField
                                    className={classes.fieldWidth}
                                    error={!isDeviceTypeValid}
                                    helperText={!isDeviceTypeValid ? 'Please enter a valid device type' : ''}
                                    label='Device type'
                                    onChange={event => setDeviceType(event.target.value)}
                                    onFocus={() => setDeviceTypeValidity(true)}
                                    value={deviceType}
                                    variant='outlined'
                                />
                            }
                            explanation={<Typography>Enter compatible device type of the artifact</Typography>}
                        />
                    </Fragment>
                );
            case 2:
                return renderStepThree();
            default:
                return 'Unknown step';
        }
    }

    const renderStepContent = () => {
        if (uploadProgress) {
            return (
                <div>
                    <LinearProgress variant='determinate' value={uploadProgress} className={classes.progressBar} />
                    <span>{uploadProgress}% uploaded</span>
                </div>
            );
        } else if (downloadProgress) {
            return (
                <div>
                    <LinearProgress variant='determinate' value={downloadProgress} className={classes.progressBar} />
                    <span>{downloadProgress}% downloaded</span>
                </div>
            );
        } else if (isResponseThrown) {
            return (
                <Fragment>
                    <Typography>
                        Do you want to create another artifact?
                    </Typography>
                    <Button onClick={handleReset} className={classes.button}>
                        Start again
                    </Button>
                </Fragment>
            );
        } else if (errorMessage) {
            return (
                <Fragment>
                    <Typography>
                        Oops! An error has occured.
                    </Typography>
                    <Typography>
                        {errorMessage}
                    </Typography>
                    <div className={classes.errorActions}>
                        <Button onClick={handleRetry}>
                            Retry
                        </Button>
                        <Button onClick={() => {
                            setErrorMessage('');
                            handleBack();
                        }}>
                            Go back
                        </Button>
                    </div>
                </Fragment>
            );
        } else {
            return (
                <Grid
                    alignItems='center'
                    container
                    direction='column'
                    justify='flex-start'
                >
                    <Grid item className={classes.formContainer}>
                        {renderFormContent()}
                    </Grid>
                    <Grid item>
                        <Button
                            className={classes.backButton}
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            variant='contained'
                        >
                            Back
                        </Button>
                        <Button
                            color='primary'
                            onClick={handleNext}
                            variant='contained'
                        >
                            {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                        </Button>
                    </Grid>
                </Grid>
            );
        }
    }

    return (
        <Fragment>
            <CssBaseline />
            <Container maxWidth={false} className={classes.formContent}>
                <Stepper
                    alternativeLabel
                    activeStep={activeStep}
                    classes={{
                        root: classes.stepperContainer
                    }}
                    connector={
                        <StepConnector
                            classes={{
                                alternativeLabel: classes.stepLinePosition,
                                line: classes.stepLine
                            }}
                        />
                    }
                >
                    {steps.map((label, index) => (
                        <Step key={index}>
                            <StepLabel
                                classes={{
                                    label: classes.stepLabel
                                }}
                                StepIconProps={{
                                    classes: {
                                        root: classes.stepIcon
                                    }
                                }}
                            >
                                {label}
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
                {renderStepContent()}
            </Container>
        </Fragment>
    );
}
