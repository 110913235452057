import axios from 'axios';
import { BASE_URL } from './config';

export const createArtifact = (requestPayload, callback) => async () => {
    let preResponse = {
        uploadProgress: 0,
        downloadProgress: 0
    };
    try {
        const response = await axios({
            url: `${BASE_URL}/api/create`,
            method: 'POST',
            data: requestPayload,
            headers: {
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Origin': '*'
            },
            responseType: 'blob',
            onUploadProgress: progressEvent => {
                preResponse = {
                    uploadProgress: Math.round((progressEvent.loaded * 100) / progressEvent.total),
                    downloadProgress: 0
                }
                callback(preResponse, null, null);
            },
            onDownloadProgress: progressEvent => {
                preResponse = {
                    uploadProgress: 0,
                    downloadProgress: Math.round((progressEvent.loaded * 100) / progressEvent.total)
                }
                callback(preResponse, null, null);
            }
        });
        callback(null, response, null);
    } catch (error) {
        callback(null, null, error.message);
    }
}