import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import React, { Fragment } from 'react';
import { useStyles } from '../theme';

export default () => {
    const classes = useStyles();
    const forkLink = `https://gitlab.com/aikaanlabs-public/mender-artifact-generator`;

    return (
        <Fragment>
            <CssBaseline />
            <AppBar position={'sticky'} elevation={0} className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    <a
                        className={classes.toolbarLogoLink}
                        href={`https://aikaan.io`}
                        rel={'noopener noreferrer'}
                        target={'_blank'}
                    >
                        <img
                            alt={'aikaan_logo'}
                            className={classes.toolbarLogo}
                            src={require(`../assets/images/LoginScreenLogo.png`)}
                        />
                    </a>
                    <Button variant='outlined' className={classes.navLink} onClick={() => window.open(forkLink)}>
                        Fork it on GitLab
                    </Button>
                </Toolbar>
            </AppBar>
            <Container maxWidth={false} component='main' className={classes.heroContent}>
                <Typography variant='h2' align='center' gutterBottom className={classes.heroHeader}>
                    Artifact creation tool
                </Typography>
                <Typography variant='h4' align='center' component='p' className={classes.heroSubHeader}>
                    This tool is used to create artifacts for software updates on remote IoT edge devices.
                </Typography>
                <div className={classes.heroButtons}>
                    <Grid container justify='center'>
                        <Grid item>
                            <Button
                                color='primary'
                                variant='contained'
                                onClick={() => window.scrollTo(0, document.body.scrollHeight)}
                            >
                                Get started
                            </Button>
                        </Grid>
                    </Grid>
                </div>
                <Typography className={classes.poweredBy}>
                    Powered by Mender
                </Typography>
            </Container>
        </Fragment>
    );
}
